export default {
    isLove:false,
    allMyLove:[],
    profiles :[],
    myProfile: null,
    profile: null,
    editedProfileId:'',
    profileId:null,
    editedProfile :{
        name:'',
        date:'',
        body: 2,
        height: 150,
        color:'',
        pic: null,
        image : null,
        onme:'',
        religion:''
    },

}