<template >
  <q-page >
  <div >
    <br><br>


    <h1> 3 World places to visit this new year</h1>
    <div class="wrapper">

      <div class="container" id="c0">
        <div class="image" id="i0">

          <div class="city">

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 300 225" style="enable-background:new 0 0 300 225;" xml:space="preserve">
			<ellipse class="st4" id="sh1" cx="170.5" cy ="124.75" rx="37.5" ry="37.5"/>
              <ellipse id="XMLID_15_" class="st4" cx="170.5" cy="124.75" rx="27.5" ry="27.5"/>

              <polygon id="XMLID_1_" class="st2" points="-0.301,224.5 74.849,149.5 150,224.5 "/>
              <polygon id="XMLID_2_" class="st0" points="108,224.5 74.849,149.5 150,224.5 "/>
              <polygon id="XMLID_4_" class="st2" points="207.256,225.5 253.849,179 300.443,225.5 "/>
              <polygon id="XMLID_3_" class="st0" points="274.403,225.5 253.849,179 300.443,225.5 "/>
              <line id="XMLID_5_" class="st1" x1="179" y1="202" x2="179" y2="226"/>
              <line id="XMLID_6_" class="st1" x1="179" y1="207" x2="190" y2="207"/>
              <line id="XMLID_7_" class="st1" x1="172" y1="214" x2="180" y2="214"/>
              <line id="XMLID_8_" class="st1" x1="190" y1="202" x2="190" y2="207"/>
              <line id="XMLID_9_" class="st1" x1="172" y1="207" x2="172" y2="214"/>
              <circle id="XMLID_10_" class="st3" cx="87.5" cy="98.5" r="1.5"/>
              <circle id="XMLID_11_" class="st3" cx="24.5" cy="68.5" r="2.5"/>
              <circle id="XMLID_12_" class="st3" cx="219.5" cy="18.5" r="1.5"/>
              <circle id="XMLID_13_" class="st3" cx="272.5" cy="03.5" r="2.0"/>
              <circle id="XMLID_14_" class="st3" cx="144.5" cy="12.5" r="1.0"/>
              <circle id="XMLID_10_" class="st3" cx="107.5" cy="328.5" r="1.5"/>
              <circle id="XMLID_11_" class="st3" cx="24.5" cy="58.5" r="2.5"/>
              <circle id="XMLID_12_" class="st3" cx="19.5" cy="128.5" r="1.5"/>
              <circle id="XMLID_13_" class="st3" cx="72.5" cy="113.5" r="2.0"/>
              <circle id="XMLID_14_" class="st3" cx="174.5" cy="92.5" r="1.0"/>

			</svg>

          </div>
        </div>
        <div class="story" id="s0">
          <div class="info">
            <h3>Pyramids</h3>
            <p> Built during a time when Egypt was one of the richest and most powerful civilizations in the world. Their massive scale reflects the unique role that the pharaoh played in ancient Egyptian society.</p>
          </div>
        </div>
      </div>
      <div class="container" id="c1">
        <div class="image" id="i1">
          <div class="city">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 300 225" style="enable-background:new 0 0 300 225;" xml:space="preserve">
			<ellipse class="st5" id="sh3" cx="150.5" cy ="224.75" rx="77.5" ry="77.5"/>

              <ellipse class="st5" id="sh2" cx="150.5" cy ="224.75" rx="57.5" ry="57.5"/>
              <ellipse id="XMLID_15_" class="st5" cx="150.5" cy="224.75" rx="37.5" ry="37.5"/>

              <path id="XMLID_1_" class="st6" d="M68.955,225H47.93l8.333-53.171c0.213-1.361,1.386-2.364,2.763-2.364h6.202
				c1.464,0,2.68,1.129,2.789,2.588l3.727,49.942C71.865,223.617,70.581,225,68.955,225z"/>
              <polygon id="XMLID_2_" class="st6" points="111.754,225 90.203,225 96.005,169.465 103.051,169.465 "/>
              <path id="XMLID_3_" class="st6" d="M112.233,175.682H50.767c-3.855,0-6.981-3.125-6.981-6.981v-3.182
				c0-3.796,3.033-6.896,6.827-6.979l61.466-1.351c3.914-0.086,7.134,3.064,7.134,6.979v4.533
				C119.214,172.556,116.089,175.682,112.233,175.682z"/>
              <path id="XMLID_4_" class="st6" d="M202.538,225h-15.319c-2.837,0-5.444,0.24-5.005-2.25l8.296-47.01
				c0.352-1.997,2.289-3.468,4.565-3.468h3.721c2.419,0,4.428,1.656,4.608,3.798l3.741,44.52C207.346,222.971,205.225,225,202.538,225z
				"/>
              <polygon id="XMLID_5_" class="st6" points="245.98,225 223.157,225 231.421,172.272 242.045,172.272 "/>
              <polygon id="XMLID_6_" class="st6" points="283.798,225 262.163,225 267.988,169.25 275.061,169.25 "/>
              <path id="XMLID_7_" class="st6" d="M275.011,178.032h-84.307c-4.478,0-8.107-3.63-8.107-8.107v-8.817
				c0-4.669,3.933-8.373,8.593-8.093l84.307,5.064c4.281,0.257,7.621,3.804,7.621,8.093v3.754
				C283.118,174.403,279.489,178.032,275.011,178.032z"/>

              <circle id="XMLID_11_" class="st7" cx="24.5" cy="68.5" r="2.5"/>
              <circle id="XMLID_12_" class="st7" cx="219.5" cy="28.5" r="1.5"/>
              <circle id="XMLID_13_" class="st7" cx="272.5" cy="103.5" r="2.0"/>
              <circle id="XMLID_14_" class="st7" cx="144.5" cy="92.5" r="1.0"/>

              <circle id="XMLID_11_" class="st7" cx="4.5" cy="88.5" r="2.5"/>
              <circle id="XMLID_12_" class="st7" cx="29.5" cy="38.5" r="1.5"/>
              <circle id="XMLID_13_" class="st7" cx="222.5" cy="143.5" r="2.0"/>
              <circle id="XMLID_14_" class="st7" cx="114.5" cy="42.5" r="1.0"/>

			</svg>

          </div>
        </div>
        <div class="story" id="s1">
          <div class="info">
            <h3>Stonehenge</h3>
            <p> Stonehenge is a prehistoric monument in Wiltshire, England. It was constructed in several stages between 3000 and 1500 B.C., spanninng to the Bronze Age.</p>
          </div>
        </div>
      </div>
      <div class="container" id="c2">
        <div class="image" id="i2">
          <div class="city">


            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 300 225" style="enable-background:new 0 0 300 225;" xml:space="preserve">
			<ellipse class="st5" id="sh3" cx="150.5" cy ="224.75" rx="77.5" ry="77.5"/>

              <ellipse class="st5" id="sh2" cx=	"150.5" cy ="224.75" rx="57.5" ry="57.5"/>
              <ellipse id="XMLID_15_" class="st5" cx="150.5" cy="224.75" rx="37.5" ry="37.5"/>


              <polygon id="XMLID_1_" class="st10" points="69.807,224.719 72.715,209.374 71.974,203.342 120.42,211.753 118.199,217.581
				115.799,232.384 "/>
              <polygon id="XMLID_2_" class="st10" points="77.295,204.276 79.544,191.37 78.827,186.294 119.809,193.405 118.277,198.349
				116.264,210.769 "/>
              <polygon id="XMLID_3_" class="st10" points="80.163,186.582 82.413,173.676 81.696,168.6 122.677,175.711 121.146,180.655
				119.132,193.075 "/>
              <polygon id="XMLID_4_" class="st10" points="83.032,168.888 85.282,155.982 84.565,150.906 125.546,158.017 124.015,162.961
				122.001,175.381 "/>
              <polygon id="XMLID_5_" class="st10" points="85.901,151.194 88.151,138.288 87.433,133.212 128.415,140.323 126.884,145.267
				124.87,157.687 "/>
              <polygon id="XMLID_6_" class="st10" points="88.77,133.5 91.019,120.594 90.302,115.518 131.284,122.629 129.752,127.573
				127.739,139.993 "/>
              <polygon id="XMLID_7_" class="st10" points="91.638,115.806 93.888,102.9 93.171,97.824 134.152,104.935 132.621,109.879
				130.607,122.299 "/>
              <path id="XMLID_8_" class="st10" d="M127.114,103.327L99.59,98.865l2.466-15.208c0.608-3.75,4.14-6.296,7.89-5.688l13.452,2.181
				c4.022,0.652,6.754,4.441,6.102,8.463L127.114,103.327z"/>
              <line id="XMLID_9_" class="st11" x1="109.946" y1="77.968" x2="84.783" y2="226.86"/>
              <line id="XMLID_10_" class="st11" x1="115.708" y1="79.915" x2="90.545" y2="228.807"/>
              <line id="XMLID_11_" class="st11" x1="120.804" y1="79.728" x2="95.641" y2="228.62"/>
              <line id="XMLID_12_" class="st11" x1="125.739" y1="80.529" x2="100.577" y2="229.421"/>
              <line id="XMLID_13_" class="st11" x1="100.502" y1="98.724" x2="75.339" y2="247.616"/>
              <line id="XMLID_14_" class="st11" x1="128.141" y1="103.205" x2="102.978" y2="252.097"/>
              <circle id="XMLID_11_" class="st7" cx="24.5" cy="68.5" r="2.5"/>
              <circle id="XMLID_12_" class="st7" cx="119.5" cy="28.5" r="1.5"/>
              <circle id="XMLID_13_" class="st7" cx="172.5" cy="103.5" r="2.0"/>
              <circle id="XMLID_14_" class="st7" cx="244.5" cy="92.5" r="1.0"/>
              <circle id="XMLID_11_" class="st7" cx="214.5" cy="18.5" r="2.5"/>
              <circle id="XMLID_12_" class="st7" cx="119.5" cy="38.5" r="1.5"/>
              <circle id="XMLID_13_" class="st7" cx="172.5" cy="203.5" r="2.0"/>
              <circle id="XMLID_14_" class="st7" cx="144.5" cy="12.5" r="1.0"/>

			</svg>

          </div>
        </div>
        <div class="story" id="s2">
          <div class="info">
            <h3>Tower of Pisa</h3>
            <p> The Leaning Tower of Pisa or simply the Tower of Pisa is the campanile, or freestanding bell tower, of the cathedral of the Italian city of Pisa, known worldwide for its unintended tilt</p>

          </div>
        </div>
      </div>



    </div>











<!--&lt;!&ndash;todo: fix this class and style&ndash;&gt;-->
<!--    <q-card class="c-title" v-if="dialog === 2">-->
<!--      <p class= "inputTitle text-weight-bold ">אנא בחר את מגדרך</p>-->
<!--      <br v-if="screenSize < 600">-->
<!--      <q-btn size="20px" :class= "this.localEditedProfile.myGender === 'נקבה' ? 'girl-btn' : 'girlBoy-btn'" @click="girl()">נקבה</q-btn>-->
<!--      <q-btn size="20px" :class= "this.localEditedProfile.myGender === 'זכר' ? 'boy-btn' : 'boyGirl-btn'"  @click="boy()">זכר</q-btn>-->
<!--      <q-btn size="20px" class="a-btn text-weight-bold glossy" style="margin-top: 40%; "-->
<!--             @click=" dialog++">המשך</q-btn>-->
<!--    </q-card>-->


<!--        <q-card class="d-title" v-if="dialog === 3">-->
<!--          <p class= "inputTitle text-weight-bold">כינוי: </p>-->
<!--          <q-input class="inputAdd" dark filled ref="name"  v-model="localEditedProfile.name" color="red" label="nickname *"-->
<!--                   lazy-rules-->
<!--                   :rules="[ val => val && val.length > 0 || 'Please type something']"/>-->
<!--          <p class= "inputTitle text-weight-bold ">תאריך לידה: </p>-->
<!--          <q-input class="inputAdd" dark   filled v-model="localEditedProfile.date"  type="date" color="red" label="date" hint=" date"-->
<!--                   lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']"/>-->
<!--          <p class="inputTitle text-weight-bold">אזור מגורים </p>-->
<!--          <q-input class="inputAdd" dark filled v-model="localEditedProfile.Living" color="red"  label="Living *"/>-->
<!--          <q-btn size="20px" class="a-btn text-weight-bold glossy"  @click="dialog ++">המשך</q-btn>-->
<!--        </q-card>-->


<!--    <q-card class="d-title" v-if="dialog === 4">-->
<!--      <p class="inputTitle">סגנון גוף: </p>-->
<!--      <q-badge  v-model="editedBody"   color="black">-->
<!--        {{ editedBody }}-->
<!--      </q-badge>-->
<!--      <div @click="numberBody()">-->
<!--        <q-slider class="inputAdd" dark reverse v-model="localEditedProfile.body" :min="0"-->
<!--                  :max="50" color="black"-->
<!--                  label-always/>-->
<!--      </div>-->
<!--      <p class="inputMiniTitle">חטוב/ה </p>-->
<!--      <p class="inputMiniTitle2">מלא/ה </p>-->
<!--      <br>-->


<!--      <p class="inputTitle">גובה: </p>-->
<!--      <q-input class="inputAdd" dark v-model="localEditedProfile.height" color="red" type="number" :min="150" :max="230"-->
<!--               label="height *" hint="150-230"/>-->
<!--      <p class="inputTitle">דת: </p>-->
<!--      <q-select class="inputAdd" dark  clearable filled color="red" v-model="localEditedProfile.religion" :options="options"-->
<!--                label="religion"/>-->
<!--      <q-btn size="20px" class="a-btn text-weight-bold glossy"  @click="dialog ++">המשך</q-btn>-->
<!--    </q-card>-->




<!--    <q-card class="d-title" v-if="dialog === 5">-->
<!--      <p class="inputTitle">תמונת פרופיל: </p>-->

<!--      <q-input class="inputAdd" dark v-model="localEditedProfile.image" @click="click1" @change="previewImage1" ref="input1"-->
<!--               filled type="file"/>-->

<!--      <p class="inputTitle">עוד תמונות: </p>-->

<!--      <q-input class="inputAdd" dark v-model="localEditedProfile.pic" @click="click1" @change="previewImage" ref="input1" filled-->
<!--               type="file" />-->

<!--      <p class="inputTitle">קצת על עצמי: </p>-->
<!--      <q-input class="inputAdd" dark v-model="localEditedProfile.onme" filled clearable type="textarea" style="height: 120px" label="קצת על עצמי *"/>-->

<!--      <q-btn v-if="!profile" size="15px"-->
<!--             style="background: darkred; margin-top: 20px; margin-right: 50%; color: white"-->
<!--             label="המשך לפרופיל" @click="insert()"/>-->
<!--      <q-btn v-else size="15px"-->
<!--             style="background: darkred; margin-top: 20px; margin-right: 40%; color: white"-->
<!--             label="עדכן פרופיל" @click="update()"/>-->
<!--&lt;!&ndash;      <q-btn size="20px" class="a-btn text-weight-bold glossy"  @click="dialog = 1">המשך</q-btn>&ndash;&gt;-->
<!--    </q-card>-->







  </div>
  </q-page>
</template>

<script>

const inputs = ['primary', 'secondary'];
import localStorageDriver from '../../Driver/Local-Storage'
import firebaseInstance from '../../Driver/firebase';
export default {
  name: "AddProfile",


  data() {
    return {
      picChat:'',
      screenSize: window.innerWidth,
      dialog:1,
      editedBody: '(0- רזה | 50- שמן)',
      img1:'',
      localEditedProfile: {
        Living:'',
        myGender:'',
        hisGender:false,
        name: '',
        body: 19,
        // todo: this bodyt
        // bodyt:'',
        height: 150,
        pic: null,
        image: null
      },
      model: null,
      options: [
        'דתי', 'אטאיסט', 'מסורתי', 'חילוני',
      ]
    }

  },


}



</script>

<style scoped>

@import url('https://fonts.googleapis.com/css?family=Raleway:200');
body {
  font-family: 'Raleway', sans-serif;
  /*background:#eee;*/
}

.wrapper {
  position: absolute;
  top: 10%;
  left: 40%;
  width: 300px;
  height: 500px;
  perspective: 900px;
}

.container {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  transition: .5s all ease;
  transform: rotateX(60deg) scale(0.7);
  perspective: 900px;
  box-shadow: 0px 20px 50px #555;
  animation: entry 1s linear 1;
}

#c0 {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);*/
  z-index: 300;
  box-shadow: 0px 20px 100px #555;
}

#c1 {
  /*background: linear-gradient(to bottom, #59476f 30%, #7b88d1 100%);*/
  box-shadow: 0px 20px 100px #555;
  left: 100%;
  z-index: 0;
}

#c2 {
  left: -100%;
  z-index: 0;
  /*background: linear-gradient(to bottom, #59476f 30%, #7b88d1 100%);*/
  box-shadow: 0px 20px 100px #555;
}

.container:hover {
  cursor: pointer;
  transform: rotate(0deg) scale(1) translateY(10px);
  transition: .5s all ease;
  z-index: 400;
}

.image {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 45%;
  /*background: linear-gradient(to top, #eba65b 30%, #d99267 100%);*/
}

#i1 {
  /*background: linear-gradient(to bottom, #59476f 30%, #7b88d1 100%);*/
}

#i2 {
  /*background: linear-gradient(to bottom, #59476f 30%, #7b88d1 100%);*/
}

span {
  position: absolute;
  top: 0%;
  left: 55%;
  border-radius: 100%;
  background-color: #fff;
  z-index: 10;
}

.sun {
  position: absolute;
  top: 50%;
  height: 50%;
  left: 0%;
  width: 100%;
}

.sun1 {
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 10px #fff;
}

.st4 {
  fill: #f8c56f;
  opacity: 1;
}

.st5,
.st7 {
  fill: #fff;
}

#sh2 {
  opacity: 0.5;
}

#sh3 {
  opacity: 0.3;
}

.st6 {
  fill: #59476f;
}

.st10 {
  fill: #c5a7e5;
  stroke: #222;
}

.st11 {
  stroke: #212121;
  opacity: 0.5;
}

#sh1 {
  fill: #f8c56f;
  opacity: 0.4;
}

.st0 {
  fill: #a25a62;
  stroke: none;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: #a25a62;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st2 {
  fill: #cd775c;
}

.st3 {
  fill: #fff;
  opacity: 0.3;
}

.story {
  position: absolute;
  top: 45%;
  left: 0%;
  height: 55%;
  width: 100%;
  /*background: linear-gradient(to bottom, #eba65b 0%, #d99267 40%);*/
  z-index: 30;
}

#s1 {
  /*background: linear-gradient(to top, #5b62a2 0%, #7b88d1 100%);*/
}

#s2 {
  /*background: linear-gradient(to top, #5b62a2 0%, #7b88d1 120%);*/
}

.info {
  position: absolute;
  top: 10%;
}

h3 {
  text-align: center;
  text-shadow: 0px 0px 10px #eee;
  color: #eee;
  letter-spacing: 2px;
}

h4 {
  color: #111;
  position: absolute;
  top: 30%;
  left: 30%;
  letter-spacing: 2px;
}

h1 {
  color: #111;
  position: fixed;
  top: 0%;
  left: 30%;
  letter-spacing: 2px;
}

p {
  font-size: 14px;
  color: #fff;
  padding: 0px 20px 20px 20px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 1px;
}

.page {
  position: absolute;
  top: 80%;
  width: 100%;
  left: 0%;
  height: 20%;
  z-index: 30;
}

li {
  position: absolute;
  top: 50%;
  list-style: none;
  color: #111;
}

li:nth-child(1) {
  left: 15%;
  animation: simple1 1s linear infinite;
}

li:nth-child(2) {
  right: 15%;
  animation: simple 1s linear infinite;
}

@keyframes simple {
  50% {
    transform: translateX(10px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(10px);
    opacity: 0;
  }
}

@keyframes simple1 {
  50% {
    transform: translateX(-10px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}

@keyframes entry {
  0% {
    top: -20%;
    opacity: 0.1;
  }
  100% {
    top: 0%;
  }
}

</style>